<template>
  <div>
    <b-card
      :title="
        editable
          ? 'Editar usuários vinculados a Área de trabalho'
          : 'Vincular novo usuário a Áreas de trabalho'
      "
      class="text-center text-primary"
    >
      <b-form>
        <!-- title -->
        <b-row class="my-1 m-5 mb-3 d-flex justify-content-start">
          <b-col col xs="12" sm="12" md="6" lg="6">
            <h4
              for="contact"
              class="text-center font-weight-bold text-primary pb-1"
            >
              Usuário
            </h4>
            <p>
              <strong>Email:</strong>
              {{ modelUserWorkspace.user ? modelUserWorkspace.user.email : '' }}
            </p>
            <p><strong>Nome:</strong> {{ modelUserWorkspace.username }}</p>
          </b-col>
          <b-col col xs="12" sm="12" md="6" lg="6">
            <h4
              for="contact"
              class="text-center font-weight-bold text-primary pb-1"
            >
              Área de trabalho
            </h4>

            <p>
              <strong>Nome:</strong>
              {{
                modelUserWorkspace.workspace
                  ? modelUserWorkspace.workspace.title
                  : ''
              }}
            </p>
            <p>
              <strong>CNPJ/CPF:</strong>
              {{
                modelUserWorkspace.workspace
                  ? modelUserWorkspace.workspace.num_doc
                  : ''
              }}
            </p>
          </b-col>
        </b-row>
        <!-- <hr /> -->
        <b-row class="my-1 m-5 mb-3 d-flex justify-content-start">
          <!-- Prefixo -->

          <b-col
            col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            class="justify-content-center d-flex"
          >
            <b-form-group>
              <h4
                for="contact"
                class="text-center font-weight-bold text-primary pb-1"
              >
                Acessos:
              </h4>
              <b-row>
                <b-form-checkbox
                  :disabled="loading"
                  v-model="modelUserWorkspace.is_admin"
                  name="checkbox-1"
                  
                  
                  class="m-1"
                >
                  Administrador
                </b-form-checkbox>
                <b-form-checkbox
                  :disabled="loading"
                  v-model="modelUserWorkspace.is_manager"
                  name="checkbox-1"
                  
                  
                  class="m-1"
                >
                  Operacional
                </b-form-checkbox>
                <b-form-checkbox
                  :disabled="loading"
                  v-model="modelUserWorkspace.is_transaction_manager"
                  name="checkbox-1"
                  
                  
                  class="m-1"
                >
                  Financeiro
                </b-form-checkbox>
                <b-form-checkbox
                  :disabled="loading"
                  v-model="modelUserWorkspace.is_blocked"
                  name="checkbox-1"
                  
                  
                  class="m-1"
                >
                  Inativo
                </b-form-checkbox>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
        <hr class="invoice-spacing mb-3" />
        <b-row class="d-flex justify-content-between">
          <b-col class="justify-content-between d-flex">
            <!-- back -->
            <b-button
              variant="warning"
              class="mr-2"
              :disabled="loading"
              @click="
                $router.push(`/workspaces-users/${$route.params.workspace_id}`)
              "
              >Voltar</b-button
            >
            <!-- update -->
            <b-button
              v-if="editable"
              :disabled="loading"
              variant="primary"
              @click="updateUserWorkspace"
            >
              <span v-if="!loading">Salvar as alterações</span>
              <b-spinner v-if="loading" label="Loading..."></b-spinner>
            </b-button>
            <!-- saveWorkspace -->
            <b-button v-else variant="primary" @click="saveWorkspace">
              <b-spinner v-if="loading" label="Loading..."></b-spinner>
              <span v-if="!loading">Salvar</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BButton,
  // BSpinner,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  name: 'new-user-workspace',
  props: {
    editable: {
      Type: Boolean,
      default: false,
    },
    user_id: {
      Type: String,
      default: null,
    },
    workspace_id: {
      Type: String,
      default: null,
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BButton,
    // BSpinner,
  },
  data() {
    return {
      modelUserWorkspace: {
        is_admin: false,
        is_manager: false,
        is_blocked: false,
        is_transaction_manager: false,
        username: null,
        workspace: null,
        user: null,
      },
      loading: false,
    };
  },
  created() {
    if (this.editable) {
      this.getUserInfo();
    }
  },
  methods: {
    saveWorkspace() {
      this.$store
        .dispatch('saveWorkspace', this.modelUserWorkspace)
        .then((resp) => {
          if (resp) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Área de trabalho cadastrado com sucesso',
                icon: 'SuccessIcon',
                variant: 'success',
              },
            });
            this.$router.push({
              name: `/workspaces-users/${this.$route.params.workspace_id}`,
            });
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao cadastrar àrea de trabalho',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },
    getUserInfo() {
      this.$store
        .dispatch('getUserWorkspace', {
          id: this.user_id,
          workspace_id: this.workspace_id,
        })
        .then((resp) => {
          this.modelUserWorkspace.username = resp.username;
          this.modelUserWorkspace.workspace = resp.access.workspaces;
          this.modelUserWorkspace.is_admin = resp.access.is_admin;
          this.modelUserWorkspace.is_blocked = resp.access.is_blocked;
          this.modelUserWorkspace.is_manager = resp.access.is_manager;
          this.modelUserWorkspace.is_transaction_manager =
            resp.access.is_transaction_manager;
          this.modelUserWorkspace.user = resp;
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro buscar àrea de trabalho',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },
    updateUserWorkspace() {
      this.loading = true;
      this.$store
        .dispatch('updateUserWorkspace', this.modelUserWorkspace)
        .then(() => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Permissões atualizadas com sucesso',
              icon: 'SuccessIcon',
              variant: 'success',
            },
          });
          this.$router.push(
            `/workspaces-users/${this.$route.params.workspace_id}`
          );
        })
        .catch(() => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao atualizar Permissões',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },
  },
};
</script>
